import React, { Fragment } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasClassicTheme } from 'common/src/theme/saasClassic';
import { ResetCSS } from 'common/src/assets/css/style';

import {
  BlogStyle,
  // GlobalStyle,
  ContentWrapper,
} from '../../containers/SaasClassic/saasClassic.style';

import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import Image from 'reusecore/src/elements/Image';
import Text from 'reusecore/src/elements/Text';

import BannerSection from '../../containers/SaasClassic/Banner';
import Navbar from '../../containers/SaasClassic/Navbar';
import BlogPostList from '../../containers/SaasClassic/BlogPostList';
// import ServiceSection from '../containers/SaasClassic/Service';
// import PricingSection from '../containers/SaasClassic/Pricing';
// import PartnerSection from '../containers/SaasClassic/Partner';
// import TrialSection from '../containers/SaasClassic/Trial';
// import FeatureSection from '../containers/SaasClassic/Feature';
// import UpdateScreen from '../containers/SaasClassic/UpdateScreen';
// import TestimonialSection from '../containers/SaasClassic/Testimonial';

import Newsletter from '../../containers/SaasClassic/Newsletter';
import Footer from '../../containers/SaasClassic/Footer';
import '@redq/reuse-modal/es/index.css';
import SEO from '../../components/seo';

//export default () => {
const Blog = ({ row, col, contentWrapper, blogTitle, postTitle }) => {
  const data = useStaticQuery(
    graphql`
      # query BlogIndexQuery {
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(\/covid19\/.*.md)$/" } }
        ) {
          edges {
            node {
              id
              frontmatter {
                path
                title
                date
                author
                image
                audio
              }
              excerpt
            }
          }
        }
      }
    `
  );

  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <SEO title="podstacks | Featured COVID-19 Podcasts" />
        <Modal />
        <ResetCSS />
        <BlogStyle />
        {/* <GlobalStyle /> */}

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <Container className="blog_post_list">
            <Box {...row}>
              <Box {...contentWrapper}>
                <Box {...row}>
                  <Heading {...blogTitle} content="Featured COVID-19 Posts" />
                  <BlogPostList data={data} />
                </Box>
              </Box>
            </Box>
          </Container>

          <Newsletter 
            podcastSearch={true}
            titleText="Calling all COVID-19 podcasters!"
            descText="Sign up and we'll transcribe your episodes for FREE."
            type="covid19"
          />
          {/* <BannerSection /> */}
          {/* <ServiceSection /> */}
          {/* <UpdateScreen /> */}
          {/* <FeatureSection /> */}
          {/* <PartnerSection /> */}
          {/* <PricingSection /> */}
          {/* <TestimonialSection /> */}
          {/* <TrialSection /> */}
          {/* <Newsletter /> */}
          {/* <Footer /> */}
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

Blog.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '50px',
    mb: '50px',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 2],
    pl: ['30px', '30px', '30px', '25px', '45px'],
    pr: ['30px', '30px', '30px', '25px', '45px'],
    mb: ['50px', '70px'],
  },
  contentWrapper: {
    width: ['100%', '100%', '60%', '60%', '60%'],
    mb: '40px',
  },
  blogTitle: {
    fontSize: ['24px', '32px', '40px', '42px', '46px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
};

export default Blog;
